"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
/**
 * Determines if the given param is an object. {}
 * @param obj
 */
exports.isObject = function (obj) { return Object.prototype.toString.call(obj) === '[object Object]'; }; // eslint-disable-line
var useMounted = function () {
    var mounted = react_1.useRef(false);
    react_1.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    return mounted;
};
function useRefState(initialState, blockIfUnmounted) {
    if (blockIfUnmounted === void 0) { blockIfUnmounted = true; }
    var mounted = useMounted();
    var _a = react_1.useState(initialState), reactState = _a[0], setReactState = _a[1];
    var state = react_1.useRef(reactState);
    var setState = react_1.useCallback(function (arg) {
        if (!mounted.current && blockIfUnmounted)
            return;
        state.current = (typeof arg === 'function') ? arg(state.current) : arg;
        setReactState(state.current);
    }, []);
    return [state, setState];
}
exports.useRefState = useRefState;
exports.default = useRefState;
