'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _debug = require('./debug');

var _debug2 = _interopRequireDefault(_debug);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Condition = function () {
  function Condition(properties) {
    _classCallCheck(this, Condition);

    if (!properties) throw new Error('Condition: constructor options required');
    var booleanOperator = Condition.booleanOperator(properties);
    Object.assign(this, properties);
    if (booleanOperator) {
      var subConditions = properties[booleanOperator];
      var subConditionsIsArray = Array.isArray(subConditions);
      if (booleanOperator !== 'not' && !subConditionsIsArray) {
        throw new Error('"' + booleanOperator + '" must be an array');
      }
      if (booleanOperator === 'not' && subConditionsIsArray) {
        throw new Error('"' + booleanOperator + '" cannot be an array');
      }
      this.operator = booleanOperator;
      // boolean conditions always have a priority; default 1
      this.priority = parseInt(properties.priority, 10) || 1;
      if (subConditionsIsArray) {
        this[booleanOperator] = subConditions.map(function (c) {
          return new Condition(c);
        });
      } else {
        this[booleanOperator] = new Condition(subConditions);
      }
    } else if (!Object.prototype.hasOwnProperty.call(properties, 'condition')) {
      if (!Object.prototype.hasOwnProperty.call(properties, 'fact')) {
        throw new Error('Condition: constructor "fact" property required');
      }
      if (!Object.prototype.hasOwnProperty.call(properties, 'operator')) {
        throw new Error('Condition: constructor "operator" property required');
      }
      if (!Object.prototype.hasOwnProperty.call(properties, 'value')) {
        throw new Error('Condition: constructor "value" property required');
      }

      // a non-boolean condition does not have a priority by default. this allows
      // priority to be dictated by the fact definition
      if (Object.prototype.hasOwnProperty.call(properties, 'priority')) {
        properties.priority = parseInt(properties.priority, 10);
      }
    }
  }

  /**
   * Converts the condition into a json-friendly structure
   * @param   {Boolean} stringify - whether to return as a json string
   * @returns {string,object} json string or json-friendly object
   */


  _createClass(Condition, [{
    key: 'toJSON',
    value: function toJSON() {
      var stringify = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var props = {};
      if (this.priority) {
        props.priority = this.priority;
      }
      if (this.name) {
        props.name = this.name;
      }
      var oper = Condition.booleanOperator(this);
      if (oper) {
        if (Array.isArray(this[oper])) {
          props[oper] = this[oper].map(function (c) {
            return c.toJSON(false);
          });
        } else {
          props[oper] = this[oper].toJSON(false);
        }
      } else if (this.isConditionReference()) {
        props.condition = this.condition;
      } else {
        props.operator = this.operator;
        props.value = this.value;
        props.fact = this.fact;
        if (this.factResult !== undefined) {
          props.factResult = this.factResult;
        }
        if (this.result !== undefined) {
          props.result = this.result;
        }
        if (this.params) {
          props.params = this.params;
        }
        if (this.path) {
          props.path = this.path;
        }
      }
      if (stringify) {
        return JSON.stringify(props);
      }
      return props;
    }

    /**
     * Takes the fact result and compares it to the condition 'value', using the operator
     *   LHS                      OPER       RHS
     * <fact + params + path>  <operator>  <value>
     *
     * @param   {Almanac} almanac
     * @param   {Map} operatorMap - map of available operators, keyed by operator name
     * @returns {Boolean} - evaluation result
     */

  }, {
    key: 'evaluate',
    value: function evaluate(almanac, operatorMap) {
      var _this = this;

      if (!almanac) return Promise.reject(new Error('almanac required'));
      if (!operatorMap) return Promise.reject(new Error('operatorMap required'));
      if (this.isBooleanOperator()) {
        return Promise.reject(new Error('Cannot evaluate() a boolean condition'));
      }

      var op = operatorMap.get(this.operator);
      if (!op) {
        return Promise.reject(new Error('Unknown operator: ' + this.operator));
      }

      return Promise.all([almanac.getValue(this.value), almanac.factValue(this.fact, this.params, this.path)]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            rightHandSideValue = _ref2[0],
            leftHandSideValue = _ref2[1];

        var result = op.evaluate(leftHandSideValue, rightHandSideValue);
        (0, _debug2.default)('condition::evaluate <' + JSON.stringify(leftHandSideValue) + ' ' + _this.operator + ' ' + JSON.stringify(rightHandSideValue) + '?> (' + result + ')');
        return {
          result: result,
          leftHandSideValue: leftHandSideValue,
          rightHandSideValue: rightHandSideValue,
          operator: _this.operator
        };
      });
    }

    /**
     * Returns the boolean operator for the condition
     * If the condition is not a boolean condition, the result will be 'undefined'
     * @return {string 'all', 'any', or 'not'}
     */

  }, {
    key: 'booleanOperator',


    /**
     * Returns the condition's boolean operator
     * Instance version of Condition.isBooleanOperator
     * @returns {string,undefined} - 'any', 'all', 'not' or undefined (if not a boolean condition)
     */
    value: function booleanOperator() {
      return Condition.booleanOperator(this);
    }

    /**
     * Whether the operator is boolean ('all', 'any', 'not')
     * @returns {Boolean}
     */

  }, {
    key: 'isBooleanOperator',
    value: function isBooleanOperator() {
      return Condition.booleanOperator(this) !== undefined;
    }

    /**
     * Whether the condition represents a reference to a condition
     * @returns {Boolean}
     */

  }, {
    key: 'isConditionReference',
    value: function isConditionReference() {
      return Object.prototype.hasOwnProperty.call(this, 'condition');
    }
  }], [{
    key: 'booleanOperator',
    value: function booleanOperator(condition) {
      if (Object.prototype.hasOwnProperty.call(condition, 'any')) {
        return 'any';
      } else if (Object.prototype.hasOwnProperty.call(condition, 'all')) {
        return 'all';
      } else if (Object.prototype.hasOwnProperty.call(condition, 'not')) {
        return 'not';
      }
    }
  }]);

  return Condition;
}();

exports.default = Condition;